import React, { useState, useEffect } from "react";
import BlogDropdown from "../../Components/Blogs";
import SearchIcon from '@mui/icons-material/Search';
import PoiCard from "../../Components/Poi/poiCard";
import { Box, MenuItem, FormControl, Select, InputLabel, Typography, Button } from '@mui/material' ;
import { url } from "../../urlConfig";
import { useParams } from "react-router-dom";
import AttractionCard from "../../Components/Attractions/AttractionCard";
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";
import Cookies from "js-cookie";

function AllPoi({language}) {
    const [pois, setPois] = useState([]);
    const [sortBy, setSortBy] = useState('');
    const [country, setCountry] = useState('');
    // const [language, setLanguage] = useState('English');
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true);
    const { categoryId } = useParams();
    const [countryData, setCountryData] = useState([])
    const [categoryData, setCategoryData] = useState([])
    const [category, setCategory] = useState(categoryId)

    const isArabic = language == "ar";
    useEffect(() => {
        const fetchPois = async () => {
            try {
                const response = await fetch(`${url}/all_pois-2/en?sortBy=${sortBy}&country=${country}&category=${category}&type=all`);
                const result = await response.json();
                if (result.status) {
                    setPois(result.data);
                    console.log(result.data)
                } else {
                    console.error("Failed to fetch POIs");
                }
            } catch (error) {
                console.error("Error fetching POIs:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPois();
    }, [country, sortBy, category]);

    useEffect(() => {
        fetch(`${url}/country`, {
            headers: { "Content-Type": "application/json" }
        }).then((res) => { return res.json() })
            .then(response => {
                setCountryData(response.data)
            })
        fetch(`${url}/all-category`, {
            headers: { "Content-Type": "application/json" }
        }).then((res) => { return res.json() })
            .then(response => {
                setCategoryData(response.data)
            })
    }, [])

    const handleClearAll = () => {
        setCountry('All');
        // setLanguage('English');
    };

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    };
    const handleCategoryChange = (event) => {
        setCategory(event.target.value)
    };

    const filteredPois = pois.filter((poi) =>
        poi.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className={isArabic ? "text-right" : "text-left"}>
            <NavBar language={language || Cookies.get("lang")}/>
            <div className={`lg:ml-40 max-sm:ml-4 mt-8 flex text-[13px] ${isArabic ? "lg:ml-0 lg:mr-40 flex-row-reverse" : ""}`}>
                {isArabic ? (
                    <>
                        <span>كل النقاط المثيرة للاهتمام</span> {" < "} <span>الرئيسية</span>
                    </>
                ) : (
                    <>
                        <span>Home</span> {" > "} <span>All POI</span>
                    </>
                )}
            </div>

            <div className={`lg:flex ${isArabic ? "flex-row-reverse lg:mr-40 max-sm:mr-4" : "lg:ml-40 max-sm:ml-4"} lg:mt-20`}>
                <div className="lg:w-[25%]">
                    <h2 className="font-semibold text-[30px] mt-2 max-sm:mt-5">{isArabic ? "النقاط المثيرة للاهتمام" : "POIs"}</h2>
                    <div className="lg:hidden flex justify-between">
                        <div className="mt-5">
                            <div className="relative">
                                <SearchIcon className={`absolute ${isArabic ? "left-3" : "right-3"} top-1/2 transform -translate-y-1/2 text-gray-400`} />
                                <input
                                    type="text"
                                    className={`outline-none rounded-lg py-1 ${isArabic ? "pr-3 pl-10" : "pl-3 pr-10"}`}
                                    placeholder={isArabic ? "ابحث عن نقطة الاهتمام..." : "Search POI..."}
                                    style={{ border: `1px solid black` }}
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />

                            </div>
                        </div>
                    </div>
                    <Box className="" sx={{ width: 280, border: '1px solid #ccc', borderRadius: '8px', padding: '12px', marginTop: "33px" }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                            <Typography variant="h6">{isArabic ? "تصفية" : "Filter"}</Typography>
                            <Button color="error" onClick={handleClearAll}>
                                {isArabic ? "مسح" : "Clear"}
                            </Button>
                        </Box>
                        <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                            <InputLabel>{isArabic ? "ترتيب حسب" : "Sort by"}</InputLabel>
                            <Select
                                value={sortBy}
                                onChange={(e) => setSortBy(e.target.value)}
                                label={isArabic ? "ترتيب حسب" : "Sort by"}
                                className='bg-gray-100'
                            >
                                <MenuItem value="popular">{isArabic ? "الأكثر شعبية" : "Popular"}</MenuItem>
                                <MenuItem value="Newest">{isArabic ? "الأحدث" : "Newest"}</MenuItem>

                            </Select>
                        </FormControl>

                        

                        <FormControl fullWidth sx={{ marginTop: '16px' }}>
                            <InputLabel>{isArabic ? "الدولة" : "Country"}</InputLabel>
                            <Select
                                value={country}
                                onChange={handleCountryChange}
                                label={isArabic ? "الدولة" : "Country"}
                                className='bg-gray-100'
                            >
                                <MenuItem value="All">{isArabic ? "الكل" : "All"}</MenuItem>
                                {countryData?.map((country, index) => (
                                    <MenuItem key={index} value={country._id}>
                                        {isArabic ? ` ${country.ar_name}` : country?.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {/* <FormControl fullWidth sx={{ marginTop: '16px' }}>
                            <InputLabel>{isArabic ? "الدولة" : "Category"}</InputLabel>
                            <Select
                                value={category}
                                onChange={handleCategoryChange}
                                label={isArabic ? "الدولة" : "Country"}
                                className='bg-gray-100'
                            >
                                <MenuItem value="All">{isArabic ? "الكل" : "All"}</MenuItem>
                                {categoryData.map((country, index) => (
                                    <MenuItem key={index} value={country._id}>
                                        {isArabic ? ` ${country.ar_categoryName}` : country.categoryName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}
                    </Box>
                </div>
                <div className="lg:w-[65%]">
                    <div className={`max-sm:hidden flex justify-between ${isArabic ? "flex-row-reverse" : ""}`}>
                        <div className="font-semibold text-[18px] mt-1 text-gray-400">
                            {filteredPois.length} {isArabic ? "نتائج" : "results"}
                        </div>
                        <div>
                            <div className="relative">
                                <SearchIcon className={`absolute ${isArabic ? "left-3" : "right-3"} top-1/2 transform -translate-y-1/2 text-gray-400`} />
                                <input
                                    type="text"
                                    className={`outline-none rounded-lg py-1 ${isArabic ? "pr-3 pl-10" : "pl-3 pr-10"}`}
                                    placeholder={isArabic ? "ابحث عن نقطة الاهتمام..." : "Search POI..."}
                                    style={{ border: `1px solid black` }}
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`mt-5 font-semibold lg:hidden ${isArabic ? "text-right" : ""}`}>{isArabic ? "جميع النقاط المثيرة للاهتمام:" : "All POIs:"}</div>
                    <div className={`lg:hidden font-semibold text-[18px] mt-1 text-gray-400 ${isArabic ? "text-right" : ""}`}>
                        {filteredPois.length} {isArabic ? "نتائج" : "results"}
                    </div>
                    {loading ? (
                        <div className="text-center mt-10 text-xl font-semibold text-gray-500">
                            {isArabic ? "جارٍ تحميل جميع النقاط المثيرة للاهتمام..." : "Loading all POIs..."}
                        </div>
                    ) : (
                        <div className={`grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6 ${isArabic ? "flex-row-reverse" : ""}`}>
                            {filteredPois.map(poi => (
                                <AttractionCard key={poi._id} poi={poi} />
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AllPoi;
