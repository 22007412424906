import React from "react";
import { Divider } from "@mui/material";
import PopularDestinationCard from "./PopularDestinationCard";
import { primary } from "../../AppConfig";
import { url } from "../../urlConfig"
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useEffect, useState } from "react";
import CardList2 from "../Shimmer/card2";
import Cookies from "js-cookie"
import axios from "axios";
import { useNavigate } from "react-router-dom";

function PopularDestination({stateData,language}) {
  const navigate = useNavigate();
  // const cards=["1","2","3","4","5","6",]
  const [state, setstate] = useState(stateData)
  const [countryId, setCountryId] = useState("");
  const [load, setLoad] = useState(!!stateData)
  useEffect(() => {
    // console.log("Params:", poiId); // Log params
    // console.log("Initial Data:", initialData); // Log initial data

    if (stateData === undefined) {
      console.log("hello")
      const fetchStateData = async () => {
        try {

          const stateResponse = await axios.get(`${url}/all-popular-states`);
          setstate(stateResponse.data.data);
          console.log(stateResponse.data)
          setLoad(true);
        } catch (error) {
          console.error("Error fetching data on client-side navigation:", error);
          setLoad(false);
        }
      };

      fetchStateData();
    }
  }, [state]);




  const handelExploreMore = () => {
    navigate(`/${language}/all-states`); window.scrollTo({
      top: 0,
      behavior: 'smooth', // This adds a smooth scroll effect
    })
  };


      
    return ( 
        <>
        <div>
            {language==="ar" ?   <div className="flex justify-end max-sm:ms-5 me-5 lg:-mt-36 max-sm:-mt-40">
               
              
              {/* <div className="max-sm:hidden ms-10 mt-4">
              <KeyboardArrowLeftIcon className="rounded-full cursor-pointer" style={{border:"1px solid black",fontSize:"40px"}}/>
              <KeyboardArrowRightIcon className="rounded-full ms-4 cursor-pointer" style={{border:`1px solid ${primary}`,fontSize:"40px"}}/>
              </div> */}
          <div className="lg:w-[60%] max-sm:w-[60%]" style={{ borderBottom: "1px solid black" }}>

          </div>
          <div>
            <p className="text-xl max-sm:text-[16px] flex justify-end">الى اين اذهب</p>
            <p className="lg:text-4xl max-sm:text-[20px] font-bold" style={{ color: `${primary}` }}>الوجهات الشعبية</p>
          </div>
        </div>
          :
          <div className="flex lg:ms-20 max-sm:ms-5 lg:-mt-36 max-sm:-mt-5 ">
            <div>
              <p className="text-[25px] max-sm:text-[16px] max-sm:font-light" >Where To Go</p>
              <p className="lg:text-[40px] max-sm:text-[20px] font-bold lg:mt-2 max-sm:mt-1" style={{ color: `${primary}` }}>Popular Destinations</p>
            </div>
            <div className="lg:w-[55%] max-sm:w-[40%]" style={{ borderBottom: "1px solid black" }}>

            </div>
            <div className="max-sm:hidden ms-10 mt-6">
              <KeyboardArrowLeftIcon className="rounded-full cursor-pointer" style={{ border: "1px solid black", fontSize: "40px" }} />
              <KeyboardArrowRightIcon className="rounded-full ms-4 cursor-pointer" style={{ border: `1px solid ${primary}`, fontSize: "40px" }} />
            </div>
          </div>
        }
      </div>
      {/* {Cookies.get("lang")==="ar" ?  */}
      {/* <div className="flex flex-row-reverse lg:ms-20 max-sm:ms-5 lg:gap-20 max-sm:gap-5  overscroll-x-conatin overflow-x-scroll mt-5">
        {category.map(i=>(
<PopularDestinationCard category={i}/>
        ))}
    </div> */}

      {load === false ?
        <div>
          <div className="flex  lg:ms-20 max-sm:ms-5 lg:gap-20 max-sm:gap-5  overscroll-x-conatin overflow-x-scroll mt-5">

            <CardList2 />
            <CardList2 />
            <CardList2 />
            <CardList2 />
            <CardList2 />

          </div>
        </div>
        :

    <div
  className={`flex lg:ms-20 max-sm:ms-5 lg:gap-7 max-sm:gap-3 lg:w-[90%] overscroll-x-contain overflow-x-scroll lg:mt-10 max-sm:mt-6 ${
    language === "ar" ? "flex-row-reverse max-sm:me-5" : ""
  }`}
>
  {state?.map((i,key) => (
    <PopularDestinationCard key={key} state={i} language={language} />
  ))}
</div>

}
    {/* } */}
        {language==="ar" ? 
         <div className="flex flex-row-reverse mb-5 lg:-mt-8 max-sm:mt-4 lg:mr-16 max-sm:mr-5">
         <button onClick={handelExploreMore} className="border-2 border-black rounded-full max-sm:text-[14px] mt-5 lg:px-8 max-sm:px-2 lg:py-1 font-semibold hover:bg-[#9747FF] hover:border-white hover:text-white ">اكتشف المزيد         <ChevronRightIcon sx={{fontSize:"18px"}}/></button>
     </div>
     :
     <div className=" mb-5 lg:mt-5 max-sm:mt-4 lg:ms-20 max-sm:ms-5 ">
     <button onClick={handelExploreMore} className=" border-2 border-black font-semibold rounded-full -mt-14 max-sm:text-[14px] max-sm:px-2 max-sm:p-0 px-7 py-1 hover:bg-[#5B27D5] hover:border-white hover:text-white ">Explore More<ChevronRightIcon sx={{fontSize:"18px"}}/> </button>
 </div>
     } 
       
        </>
     );
}

export default PopularDestination;